import React from "react";
import Layout from "../components/layout/MainLayout";
import { graphql, useStaticQuery } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { Crumbs } from "../components/common/ntmwd/elements/Crumbs";
import { SEO, WMKLink } from "wmk-lib";
import { slugify } from "../components/common/MediaGallery/util/tagging";
import { subdomainUrl, twitter, siteTitle } from "../vars/helpers";

export default () => {
  const { tags } = useStaticQuery(query);
  return (
    <Layout>
      <SEO
        baseUrl={subdomainUrl}
        title={"Tags"}
        siteTitle={siteTitle}
        slug="/tags"
        twitterHandle={twitter}
        twitterImage={null}
        ogImage={null}
      />
      <Container>
        <Row>
          <Crumbs
            breadcrumbs={[
              { title: "Home", to: "https://ntmwd.com/", target: "self" },
              { title: "News Room", to: "/" },
              { title: "Tags", to: "/tags" },
            ]}
          />
        </Row>
        <Row className="flex-column">
          {tags.edges.map((tag) => {
            const { name } = tag.node;
            return (
              <Col key={name}>
                <WMKLink to={"/tags/" + slugify(name)}>{name}</WMKLink>
              </Col>
            );
          })}
        </Row>
      </Container>
    </Layout>
  );
};

const query = graphql`
  {
    tags: allWpTag {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`;
